import React, { useState } from 'react';
import styled from 'styled-components'
import { Container, Row, Col } from 'styled-bootstrap-grid2'
import ContainerText from '../../components/container_text'
import SEO from '../../components/seo'
import background from '../../images/backgrounds/contact.jpg'

const Title = styled.h3`
  padding-bottom: 2rem;
`

const InputContainer = styled.div`
`
const Hidden = styled.div`
  display: none;
`

const Label = styled.label`
  width: 100%;
  clear: left;
  float: left;
  margin-bottom: 1rem;
  font-style: italic;
  color: #000;
  span {
    opacity: 1;
    transform: translateY(18px);
    display: block;
    transition:all 0.3s ease-in-out;
  }
  &.open {
    span {
      transform: translateY(0px);
      opacity: 0;
    }
  }
  input {
    clear: left;
    width: 100%;
    float: left;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #000;
    font-family: 'minerva-modern', Garamond, Georgia, 'Times New Roman', Times, serif;
    font-style: italic;
    padding: 5px 15px 5px 15px;
    -webkit-appearance:none;
    color: #000;
    background-color: transparent;
    font-size: 12px;
    line-height: 20px;
    transition:all 0.3s ease-in-out;
    outline: none;
  }
`

const Textarea = styled.textarea`
  width: 100%;
  height: 200px;
  margin-bottom: 1rem;
  float: left;
  outline: 0;
  padding: 5px;
  resize: none;
  background-color: transparent;
  border-left: none;
  border-top: none;
  border-right: none;
  border-bottom: 1px solid #000;
  font-family: 'minerva-modern', Garamond, Georgia, 'Times New Roman', Times, serif;
  font-style: italic;
  -webkit-appearance:none;
  @media (max-width: 768px) {
    height: 50px;
  }
  color: #000;
  &:focus {
    border: 1px solid #000;
  }
`
const Submit = styled.input`
  border: none;
  cursor: pointer;
  width: 100%;
  font-family: 'minerva-modern', Garamond, Georgia, 'Times New Roman', Times, serif;
  text-transform: uppercase;
  outline: 0;
  font-weight: bold;
  font-size: 13px;
  background-color: transparent;
  -webkit-appearance:none;
  color: #000;
  &.submit-disabled {

  }
`

const Contact = (props) => {

  const [fullnameFocus, setfullnameFocus] = useState(false);
  const [emailFocus, setemailFocus] = useState(false);
  const [subjectFocus, setsubjectFocus] = useState(false);

  const [contact, setContact] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const [response, setResponse] = useState({
    type: '',
    message: ''
  });

  const handleChange = e =>
    setContact({ ...contact, [e.target.name]: e.target.value });

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const res = await fetch('https://usebasin.com/f/0b2fe97f50d2.json', {
        method: 'POST',
        body: JSON.stringify(contact),
        headers: { 'Content-Type': 'application/json' }
      });

      const json = await res.json();

      if (json.success) {
        setResponse({
          type: 'success',
          message: 'Thank you for reaching out to us.'
        });
      } else {
        setResponse({
          type: 'error',
          message: json.message
        });
      }
    } catch (e) {
      console.log('An error occurred', e);
      setResponse({
        type: 'error',
        message: 'An error occured while submitting the form'
      });
    }

    console.log(response.message);

  };

  const bgStyle = { backgroundImage: `url(${background})`, backgroundSize: 'cover', 'backgroundRepeat': 'no-repeat' };

  return (
    <div style={bgStyle}>
      <SEO title="Contact" keywords={[`Contact`,`Theodora`, `Warre`]} />
      <ContainerText top longpage>
        <Container>
          <Row>
            <Col xl="6" lg="6" md="12" sm="12">
              <Title>Contact</Title>
              <p>Should you be interested in discussing a bespoke commission please email <a href="mailto:info@theodorawarre.co.uk">info@theodorawarre.co.uk</a></p>
            </Col>
            <Col xl="6" lg="6" md="12" sm="12">
              <form
                action='https://usebasin.com/f/0b2fe97f50d2.json'
                method='post'
                onSubmit={handleSubmit}>

                <Hidden>
                  <input type="text" name="honeypot" />
                </Hidden>

                <InputContainer>
                <Label className={(fullnameFocus === true ||  contact.name.length > 0) ? 'open' : ''}>
                  <span>Full Name</span>
                  <input
                    onBlur={() => { setfullnameFocus(false); }}
                    onFocus={() => { setfullnameFocus(true); }}
                    onChange={handleChange}
                    type="text" name="name" value={contact.name} required />
                </Label>
                </InputContainer>

                <InputContainer>
                <Label className={(emailFocus === true || contact.email.length > 0) ? 'open' : ''}>
                  <span>Email Address</span>
                  <input
                  onBlur={() => { setemailFocus(false); }}
                  onFocus={() => { setemailFocus(true); }}
                  onChange={handleChange} type="text" name="email" value={contact.email} required />
                </Label>
                </InputContainer>

                <InputContainer>
                <Label className={(subjectFocus === true || contact.subject.length > 0) ? 'open' : ''}>
                  <span>Subject</span>
                  <input
                  onBlur={() => { setsubjectFocus(false) }}
                  onFocus={() => { setsubjectFocus(true) }}
                  onChange={handleChange}
                  type="text" name="subject" value={contact.subject}
                  required />
                </Label>
                </InputContainer>

                <Textarea name="message" value={contact.message} onChange={handleChange} required /><br />


                { response.type === 'success' &&
                  <Submit className="submit" type="submit" value="Sent" disabled />
                }

                { response.type === 'error' &&
                  <Submit className="submit" type="submit" value="Error sending" />
                }

                { response.type === '' &&
                  <Submit className="submit submit-disabled" type="submit" value="Submit" />
                }

              </form>
            </Col>
          </Row>
        </Container>
      </ContainerText>
    </div>
  );

}

export default Contact
